import {
  switchCardTypeVar,
  userTypeVar,
  menuControlVar,
} from 'src/apollo-cache'

import {
  useSavePageFilter,
  useDeleteUserFilter,
  useUpdatePortfolioTemplateStatus,
  usePurgePortfolio,
  updateUserType,
  useClonePortfolioTemplate,
  useUpdatePortfolioTemplate,
  useSavePortfolioTemplateAssetType,
  useDeletePortfolioTemplateAssetType,
  useSavePortfolioTemplateDefaultValuesType,
  useUpdatePortfolioTemplateDefaultValue,
  useSavePortfolioTemplateCollectionActivity,
  useUpdatePortfolioTemplateCollectionActivity,
  useDeletePortfolioTemplateCollectionActivity,
  useUpdatePortfolioTemplateCollectionActivityPosition,
  useUploadPortfolioTemplateFile,
  useTeardownStagePortfolioSplit,
  updateSwichView,
  useReplacePortfolioFile,
  useUploadBillOfSale,
  usePSARequestESignature,
  useExecuteBillOfSaleAction,
  useUploadPurchaseSaleAgreement,
  useListPortfolioImmediately,
} from './portfolio'
import createMenuControl from './menuControl'

import {
  useDeactivateForwardFlow,
  useSaveForwardFlow,
  useUpdateForwardFlow,
} from './forwardFlows'
import {
  useSaveAcknowledgeBco,
  useSaveComplaint,
  useUpdateComplaint,
  useSaveComplaintComment,
  useUpdateComplaintComment,
  useSaveComplaintInfoRequest,
  useUpdateComplaintInfoRequest,
  useSaveComplaintAttachment,
  useUpdateComplaintAttachmentVisibility,
} from './complaint'

export const portfolioMutation = {
  updateSwitchView: updateSwichView(switchCardTypeVar),
  updateUserType: updateUserType(userTypeVar),
  useSavePageFilter,
  useDeleteUserFilter,
  useUpdatePortfolioTemplateStatus,
  useClonePortfolioTemplate,
  useUpdatePortfolioTemplate,
  useSavePortfolioTemplateAssetType,
  useDeletePortfolioTemplateAssetType,
  useSavePortfolioTemplateDefaultValuesType,
  useUpdatePortfolioTemplateDefaultValue,
  useSavePortfolioTemplateCollectionActivity,
  useUpdatePortfolioTemplateCollectionActivity,
  useDeletePortfolioTemplateCollectionActivity,
  useUpdatePortfolioTemplateCollectionActivityPosition,
  useUploadPortfolioTemplateFile,
  useTeardownStagePortfolioSplit,
}

export const loadPortfolioMutation = {
  usePurgePortfolio,
}

export const portfolioDetailMutation = {
  useListPortfolioImmediately,
  useReplacePortfolioFile,
  useUploadBillOfSale,
  usePSARequestESignature,
  useExecuteBillOfSaleAction,
  useUploadPurchaseSaleAgreement,
}

export const forwardFlowsMutation = {
  useDeactivateForwardFlow,
  useSaveForwardFlow,
  useUpdateForwardFlow,
}
export const complaintMutation = {
  useUpdateComplaint,
  useSaveComplaint,
  useSaveAcknowledgeBco,
}
export const complaintCommentMutation = {
  useSaveComplaintComment,
  useUpdateComplaintComment,
}
export const complaintAttachmentMutation = {
  useSaveComplaintAttachment,
  useUpdateComplaintAttachmentVisibility,
}
export const commonMutation = {
  upadateMenuControl: createMenuControl(menuControlVar),
}

export const complaintInfoRequestMutation = {
  useSaveComplaintInfoRequest,
  useUpdateComplaintInfoRequest,
}
